 //  ____        __             _ _
 // |  _ \  ___ / _| __ _ _   _| | |_ ___
 // | | | |/ _ \ |_ / _` | | | | | __/ __|
 // | |_| |  __/  _| (_| | |_| | | |_\__ \
 // |____/ \___|_|  \__,_|\__,_|_|\__|___/
 // =====================================>

html {-webkit-font-smoothing: antialiased;}
body {-webkit-text-size-adjust: none;}
*:focus {outline: none;}
::selection {background: #6dbec2; color: #000;}
::-moz-selection {background: #6dbec2; color: #000;}

html, body {
  height: auto;
  position: relative;
}

hr {margin: 20px 0; border: none; border-bottom: 1px $lgrey solid;}

p, ul li, ol li, span {
  font-size: 1.1em;
  line-height: 1.6em;
  margin: 0;
  padding: 0;
}

a {
  color: $link;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

 //  ____                _
 // | __ )  ___  _ __ __| | ___ _ __ ___
 // |  _ \ / _ \| '__/ _` |/ _ \ '__/ __|
 // | |_) | (_) | | | (_| |  __/ |  \__ \
 // |____/ \___/|_|  \__,_|\___|_|  |___/
 // ====================================>

.border{
  position: absolute;
  background: black;
  z-index: 20;
  margin: 5px;
  box-sizing: border-box;

  &.vertical-border{
    top: 0;
    bottom: 0;
    width: 2px;
    height: auto;

    &.left-border{
      left: 0;
    }

    &.right-border{
      right: 0;
    }
  }

  &.horizontal-border{
    left: 0;
    right: 0;
    width: auto;
    height: 2px;

    &.bottom-border{
      bottom: 0;
    }

    &.top-border{
      top: -12px;
      left: -10px;
      right: -10px;
    }
  }
}

 //  __  __
 // |  \/  | ___ _ __  _   _
 // | |\/| |/ _ \ '_ \| | | |
 // | |  | |  __/ | | | |_| |
 // |_|  |_|\___|_| |_|\__,_|
 // ========================>

header.main{
  width: auto;
  height: 75px !important;
  position: fixed;
  z-index: 19;
  top: 12px !important;
  left: 0;
  margin: 0 12px !important;
  right: 0;
  .mainmenu {
    text-decoration: none;
    color: $text;
  }
  .container {
    height: 100%;
    margin: 0 auto;
    background: white;
    position: relative;
    padding: 30px!important;
    z-index: 0;
    box-sizing: border-box;

  }
}

nav{
  background: #fff!important;
  padding: 10px;
  box-shadow: none!important;
  .nav-wrapper {
    .address, .telephone {
      font-weight: bold;
    }
    border: none!important;
    .button-collapse i {
      color: $text;
      margin-left: 25px;
    }
    .side-nav {
      z-index: 20!important;
    }
    ul {
      li {
        a {
          color: $link;
          font-weight: 700;
          font-size: 1em;
          line-height: 75px;
          &:hover {
            background: none!important;
          }
        }
      }
    }
  }

  .logo{
    position: absolute;
    top: -22px;
    left: 50%;
    margin-left: -100px;
    background: url('../images/logo.png');
    height: 198px;
    width: 200px;
    z-index: 21;
  }

  .logo-small{
    position: absolute;
    top: 3px;
    left: 50%;
    margin-left: -46px;
    background: url('../images/kc_logo_small.png') no-repeat 50% 50%;
    height: 66px;
    width: 92px;
    z-index: 19;
    display: none;
  }
  .contacts {
    float: right;
    margin-right: 25px;
    .address, .telephone {
      padding-right: 10px;
    }
    .address, .telephone {
      float: left;
      font-weight: 700;
      color: $text;
      i {
        float: left;
        line-height: 75px;
      }
    }
  }

}
.lang-box {
  position: absolute;
  top: 75px !important;
  right: 0 !important;
  padding: 10px;

  a{
    margin-right: 15px;

    &:last-child{
      margin-right: 0
    }

    &.active{
      text-decoration: none;
      color: black;
      cursor: default;
    }
  }
}

 //  ____                 _ _
 // |  _ \ __ _ _ __ __ _| | | __ ___  __
 // | |_) / _` | '__/ _` | | |/ _` \ \/ /
 // |  __/ (_| | | | (_| | | | (_| |>  <
 // |_|   \__,_|_|  \__,_|_|_|\__,_/_/\_\
 // ====================================>

#rocket{
  position: absolute;
  top: 248px;
  left: 50%;
  margin-left: -24px;
  width: 55px;
  height: 158px;
  z-index: 10;
}
#boat {
  position: absolute;
  top: 520px;
  left: 45%;
  width: 177px;
  height: 196px;
  z-index: 10;
}

.carousel {
  min-height: 770px;
  .glide__track {
    margin: 0;
  }
  .screen {
    width: 100%;
    max-width: 2400px;
    margin: 0 auto;
    min-height: 777px;
    position: relative;
    overflow: hidden;
    .caption {
      position: absolute;
      z-index: 10;
      &.slogan {
        width: 320px;
        top: 280px;
        left: 50%;
        margin-left: -390px;
        text-align: right;
        &.see {
          text-align: left!important;
          margin-left: -240px!important;
          top: 320px!important;
        }
        .title {
          color: $red;
          font-style: italic;
          font-size: 2em;
          font-weight: 400;
        }
        .description {
          margin: 0;
          margin-top: 1em;
          padding: 0;
          color: $text;
          line-height: 1.2em;
        }
      }
    }
    #paralax-container-see, #paralax-container-sky{
      height: 760px;
      width: 2400px;
      position: absolute;
      top: -25px;
      left: 50%;
      margin-left: -1200px;
      .layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 850px;
        &.sky{
          background: url('../images/sky.png') no-repeat 50% 50%;
          z-index: 0;
        }
        &.sky1{
          background: url('../images/sky1.jpg') no-repeat 50% 50%;
          z-index: 0;
        }
        &.mountines_1{
          background: url('../images/mountines_1.png') no-repeat 50% 50%;
          z-index: 2;
        }
        &.sun_haze{
          background: url('../images/sun_haze.png') no-repeat 50% 50%;
          z-index: 2;
        }
        &.clouds {
          background: url('../images/clouds.png') no-repeat 50% 50%;
          z-index: 3;
        }
        &.mountines_2{
          background: url('../images/mountines_2.png') no-repeat 50% 50%;
          z-index: 3;
        }
        &.wave1{
          background: url('../images/wave1.png') no-repeat 50% 50%;
          z-index: 3;
        }
        &.ground{
          background: url('../images/ground.png') no-repeat 50% 50%;
          z-index: 4;
        }
        &.wave2{
          background: url('../images/wave2.png') no-repeat 50% 50%;
          z-index: 4;
        }
        &.boat {
          background: url('../images/boat.png') no-repeat 50% 80%;
          z-index: 4;
        }
        &.smoke{
          background: url('../images/smoke.png') no-repeat 50% 50%;
          z-index: 5;
        }
        &.wave3{
          background: url('../images/wave3.png') no-repeat 50% 50%;
          z-index: 5;
        }
        &.wave4{
          background: url('../images/wave4.png') no-repeat 50% 50%;
          z-index: 5;
        }
      }
    }
  }
}

//                _   _
//  ___  ___  ___| |_(_) ___  _ __  ___
// / __|/ _ \/ __| __| |/ _ \| '_ \/ __|
// \__ \  __/ (__| |_| | (_) | | | \__ \
// |___/\___|\___|\__|_|\___/|_| |_|___/
// =====================================>

.section {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1.2em;
}
section {
  width: 100%;
  overflow: hidden;
  .story {
    margin: 0 auto;
    .title {
      margin: 1em auto;
      text-align: center;
      font-size: 3em;
      line-height: 1em;
      font-weight: 700;
      padding: 1em;
      border-bottom: 2px $lgrey solid;
      color: $text;
    }
    .description {
      text-align: center;
      margin: 2em auto;
      font-size: 1.2em;
      color: $text;
    }

  }
}

 // __        __
 // \ \      / /__
 //  \ \ /\ / / _ \
 //   \ V  V /  __/
 //    \_/\_/ \___|
 // ==============>

#we{
  .container-fluid{
    background: $blue;
    height: 361px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    &:before, &:after {
     display: none;
    }
    .wrapper {
      margin: 0 auto;
      width: 1198px;
    }
    .person{
      width: 198px;
      height: 291px;
      float: left;
      margin: 0 26px;
      display: inline;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
  .caption {
    margin-bottom:15px;
    height: 40px;
  }
      .image{
        height: 291px;
        background-position: center bottom !important;
        &.evgeny{
          background: url('../images/evgeny.png') no-repeat;
        }
        &.denis{
          background: url('../images/denis.png') no-repeat;
        }
        &.ivan{
          background: url('../images/ivan.png') no-repeat;
        }
        &.anna{
          background: url('../images/anna.png') no-repeat;
        }
        &.alexander{
          background: url('../images/alexander.png') no-repeat;
        }
      }
      .caption{
        margin-top: 15px;
        p{
          margin: 0;
          text-align: center;
          color: white;
        }
      }
    }
  }
}


 //   ____
 //  / ___|__ _ _ __
 // | |   / _` | '_ \
 // | |__| (_| | | | |
 //  \____\__,_|_| |_|
 // =================>

#can{
  .container-fluid {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .wrapper {
      margin: 0 auto;
      width: 1196px;
      .abilities-container{
        .ability{
          .caption{color: $text;}
          width: 299px;
          padding: 0 20px;
          text-align: center;
          float: left;
          .image{
            height: 128px;
            width: 128px;
            margin: 10px auto 25px auto;
            // transform: scale(1) rotate(-360deg); transition-duration: 1s;
            // &:hover {transform: scale(1.2) rotate(360deg); transition-duration: 1s;}
            &.development{background: url('../images/development.png') no-repeat 50% 50%;}
            &.design{background: url('../images/design.png') no-repeat 50% 50%;}
            &.cloud{background: url('../images/cloud.png') no-repeat 50% 50%;}
            &.training{background: url('../images/training.png') no-repeat 50% 50%;}
          }
        }
      }
    }
  }
}


 //   ____      _     _
 //  / ___|_ __(_) __| |
 // | |  _| '__| |/ _` |
 // | |_| | |  | | (_| |
 //  \____|_|  |_|\__,_|
 // ====================>

.grid-item, .grid-sizer {
 width: 285px;
 margin-bottom: 20px;
}

// .grid-item--width2 { width: 33%; }



 //   ____
 //  / ___|__ _ ___  ___  ___
 // | |   / _` / __|/ _ \/ __|
 // | |__| (_| \__ \  __/\__ \
 //  \____\__,_|___/\___||___/
 // =========================>


#cases, .inner {
  .project-wrapper {
      width: 285px;
      padding: 0;
      height: 190px;
      cursor: pointer;
      position: relative;
  }
  .project-wrapper:before {
      width: 285px;
      height: 190px;
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,0);
      transition: background 300ms;
  }
  .project-wrapper:hover:before{
      background-color: rgba(0,0,0,0.72);
      content: "";
  }
  .project-content {
      padding: 0;
      margin: 0;
      width: 285px;
      height: 190px;
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      padding: 20px;
      padding-top: 30px;
      transition: opacity 300ms;
      opacity: 0;
      color: white;
      margin-top: -10px;
  }
  .project-wrapper:hover .project-content {
      opacity: 1;
  }
  .project-description {
      border: 2px solid #fff;
      width: 250px;
      height: 150px;
      border-top: 0;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
  }
  .project-title-wrap {
      position: absolute;
      left: 20px;
      top: 20px;
      width: 245px;
      overflow: hidden;
      display: block;
      text-align: center;
      height: 20px;
  }
  .project-title-viewport {
      width: 3000px;
      height: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -1500px;
      margin-top: -10px;
      text-align: center;
  }
  .project-title {
      display: inline-block;
      text-align: center;
      box-sizing: border-box;
      margin: 0 auto;
      color: white;
      vertical-align: baseline;
  }
  .project-title:before {
      content: "";
      height: 2px;
      background-color: #fff;
      vertical-align: middle;
      width: 1000px;
      display: inline-block;
      margin-right: 20px;
      margin-top: -4px;
  }
  .project-title:after {
      content: "";
      height: 2px;
      background-color: #fff;
      width: 1000px;
      vertical-align: middle;
      display: inline-block;
      margin-left:20px;
      margin-top: -4px;
  }
}


//  ____  _
// | __ )| | ___   __ _
// |  _ \| |/ _ \ / _` |
// | |_) | | (_) | (_| |
// |____/|_|\___/ \__, |
//                |___/
// =====================>

#blog {
  .content {
    img {max-width: 100%;}
  }
}

 //   ____            _             _
 //  / ___|___  _ __ | |_ __ _  ___| |_ ___
 // | |   / _ \| '_ \| __/ _` |/ __| __/ __|
 // | |__| (_) | | | | || (_| | (__| |_\__ \
 //  \____\___/|_| |_|\__\__,_|\___|\__|___/
 // =======================================>

#contacts{
  .container-fluid {
    #map, #googlemap{
      width: 100%;
      height: 60vh;
    }
    footer{
      width: 100%;
      background: $deep_blue;
      color: white!important;
      p {font-weight: 700;}
    }
  }
}


//  ___
// |_ _|_ __  _ __   ___ _ __
//  | || '_ \| '_ \ / _ \ '__|
//  | || | | | | | |  __/ |
// |___|_| |_|_| |_|\___|_|
// ==========================>

.inner {
  padding-top: 12em!important;
  article {
    .url {
      text-decoration: none;
      display: inline-block;
      padding-bottom: .2em;
      border-bottom: .2em $link dashed;
      margin-bottom: 1.5em;
      &:hover {
        border-bottom: .2em #4183C4 solid;
      }
    }
    img {max-width: 100%;}
    .author, .date {
      font-size: 2rem;
    }
    .author {
      background: $cyan;
      color: #fff;
      padding: 1rem;
    }
  }
}

.wrapper1emv {
  margin: 1em auto;
}


//  ____  _            _                     _
// | __ )| | ___   ___| | ____ _ _   _  ___ | |_ ___
// |  _ \| |/ _ \ / __| |/ / _` | | | |/ _ \| __/ _ \
// | |_) | | (_) | (__|   < (_| | |_| | (_) | ||  __/
// |____/|_|\___/ \___|_|\_\__, |\__,_|\___/ \__\___|
//                            |_|
// ==================================================>

blockquote {
 margin: 20px 0;
 padding: 0;
 min-height: 300px;
 position: relative;
 border-bottom: 1px #c5c5c5 solid;
 img {
   position: absolute;
   bottom: 0;
   left: 5px;
   height: 140px;
   float: left;
 }
 .notice {
   margin: 0;
   padding: 0;
   color: #c5c5c5;
   position: absolute;
   right: 0px;
   top: 25px;
   font-style: italic;
 }
 p {
   margin: 0;
   padding: 0;
   position: absolute;
   left: 120px;
   bottom: 25px;
   color: #4f4f4f;
 }
 .cloud {
   position: absolute;
   padding: 0;
   margin: 0;
   left: 120px;
   bottom: 60px;
   // max-width: 780px;
   background: #eeeeee;
   border: 1px #c5c5c5 solid;
   border-radius: 4px;
   color: #bd1e32;
   // min-height: 50px;

   .text {
     padding: 1em;
     line-height: 1em;
     font-weight: bold;
     font-size: 2em;
   }
   &:after {
     right: 100%;
     bottom: 18px;
     border: solid transparent;
     content: " ";
     height: 0;
     width: 0;
     position: absolute;
     pointer-events: none;
     border-color: rgba(238, 238, 238, 0);
     border-right-color: #eeeeee;
     border-width: 0px 15px 15px 0px;
     margin-top: -15px;
   }
   &:before {
     right: 100%;
     bottom: 17px;
     border: solid transparent;
     content: " ";
     height: 0;
     width: 0;
     position: absolute;
     pointer-events: none;
     border-color: rgba(197, 197, 197, 0);
     border-right-color: #c5c5c5;
     border-width: 0px 17px 17px 0;
     margin-top: -16px;
   }
 }
}


//   ___                      _     _
//  / _ \__   _____ _ __ _ __(_) __| | ___  ___
// | | | \ \ / / _ \ '__| '__| |/ _` |/ _ \/ __|
// | |_| |\ V /  __/ |  | |  | | (_| |  __/\__ \
//  \___/  \_/ \___|_|  |_|  |_|\__,_|\___||___/
// ============================================>

.btn-edged {
  border-radius: 0!important;
  margin: .1em !important;
  width: 25%;
  margin-left: 0!important;
}
